// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBxMERQX6EX2AWDRUjSiZ1GE1btJjaQJRs',
    authDomain: 'ernes-7eb3b.firebaseapp.com',
    projectId: 'ernes-7eb3b',
    storageBucket: 'ernes-7eb3b.appspot.com',
    messagingSenderId: '254721815765',
    appId: '1:254721815765:web:c01fef6a226aa6eb309e5c',
    measurementId: 'G-6N2455Z4XM', 
    vapidKey:'BBgigF08RL2RJLLZ-iUJboMBWiKWUvjJrXvEQA6xRQi5149pLVaSKdrSi9v2rMqVTP6xkoIkczKWtfFoh5htqhg', 
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
