import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { RouterModule, RouterLink } from '@angular/router';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { GoogleMap } from '@capacitor/google-maps';
import { SQLiteService } from './sql.lite.service';


@NgModule({
  declarations: [AppComponent],
  imports: [HttpClientModule, FormsModule, ReactiveFormsModule, RouterModule, RouterLink, IonicStorageModule.forRoot(),  WebBluetoothModule.forRoot({
    enableTracing: true // or false, this will enable logs in the browser's console
  }), BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [SQLiteService, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {}
