import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { SQLiteService } from './sql.lite.service';
import { Preferences, PreferencesPlugin } from '@capacitor/preferences';
import { FirebaseMessaging, GetTokenOptions, Notification, } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { GeolocationPermissionType } from '@capacitor/geolocation';
Preferences.get({ key: 'id' }).then(res =>{
  const id = res.value;
  console.log('Il tuo id è:' + id)
});
const fireConfig = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBxMERQX6EX2AWDRUjSiZ1GE1btJjaQJRs',
    authDomain: 'ernes-7eb3b.firebaseapp.com',
    projectId: 'ernes-7eb3b',
    storageBucket: 'ernes-7eb3b.appspot.com',
    messagingSenderId: '254721815765',
    appId: '1:254721815765:web:c01fef6a226aa6eb309e5c',
    measurementId: 'G-6N2455Z4XM', 
    vapidKey:'BBgigF08RL2RJLLZ-iUJboMBWiKWUvjJrXvEQA6xRQi5149pLVaSKdrSi9v2rMqVTP6xkoIkczKWtfFoh5htqhg', 
    },
};
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isWeb: boolean = false;
  private initPlugin: boolean;
  
  constructor(private router: Router, private sqlite: SQLiteService,
    private storage: Storage, private readonly platform: Platform) {
    this.storage.create();
    this.initializeApp();
    this.initializeFirebase();
  }


  storeToken(token, id) {
    Preferences.set({
      key: 'access_token',
      value: token
    });
    Preferences.set({
      key: 'id',
      value: JSON.stringify(id)
    });
    this.storage.set('id', id);
    this.storage.set("ACCESS_TOKEN", token);
  }

  

  initializeApp() {
    Geolocation.checkPermissions().then( res => {
      if(res.coarseLocation == 'granted') {
        console.log('Hai già garantito le permissions')
      } else {
        Geolocation.requestPermissions();
      }
    });
    this.platform.ready().then(async () => {
      this.sqlite.initializePlugin().then(async (ret) => {
        this.initPlugin = ret;
        if( this.sqlite.platform === "web") {
          this.isWeb = true;
          await customElements.whenDefined('jeep-sqlite');
          const jeepSqliteEl = document.querySelector('jeep-sqlite');
          if(jeepSqliteEl != null) {
            await this.sqlite.initWebStore();
            console.log(`>>>> isStoreOpen ${await jeepSqliteEl.isStoreOpen()}`);
          } else {
            console.log('>>>> jeepSqliteEl is null');
          }
        }

        console.log(`>>>> in App  this.initPlugin ${this.initPlugin}`);
      });
    });
  }
  logout(){
    Preferences.remove({ key: 'id' });
    this.router.navigateByUrl('login');
    this.storage.remove('id');
    this.storage.remove('user');
    this.storage.remove('ACCESS_TOKEN');

  
  }
  

  public async initializeFirebase() {
    const app = initializeApp(fireConfig.firebase);
    const messaging = getMessaging(app);
  }
}
